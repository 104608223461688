import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getProjectUrl } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import BlockContent from "../sanity/block-content";

import styles from "./blog-post-preview.module.css";

function BlogPostPreview(props) {
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getProjectUrl(props.slug.current)}
    >
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(780)
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
      <div className={styles.text}>
        <h2 className={cn(styles.title)}>{props.title}</h2>
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <BlockContent blocks={props._rawExcerpt} />
          </div>
        )}
        <div className={styles.date}>{format(props.publishedAt, "DD. MM YYYY")}</div>
      </div>
    </Link>
  );
}

export default BlogPostPreview;
